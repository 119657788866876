@import "~antd/dist/antd.css";

.content {
  min-height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.rotator {
  position: absolute;
  top: 50%;
  left: 51%;
  padding: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.rotator-1 {
  position: absolute;
  top: 50%;
  left: 51%;
  padding: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: rotation 0.8s ease-in-out forwards infinite;
  border-top: 3px solid #6ee7b7;
}
.rotator-2 {
  position: absolute;
  top: 50%;
  left: 51%;
  padding: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: rotation 1.03s ease-in-out forwards infinite;
  border-top: 3px solid #10b981;
}
.rotator-3 {
  position: absolute;
  top: 50%;
  left: 51%;
  padding: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  animation: rotation 1.26s ease-in-out forwards infinite;
  border-top: 3px solid #047857;
}
.rotator-4 {
  position: absolute;
  top: 50%;
  left: 51%;
  padding: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
  width: 190px;
  height: 190px;
  animation: rotation 1.37s ease-in-out forwards infinite;
  border-top: 3px solid #064e3b;
}
.logo {
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo__img {
  width: 100px;
  border-radius: 50%;
}
